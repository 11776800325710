import React from "react";
import { ThreeDots } from 'react-loader-spinner';

function Loader() {
    return (
        <div style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1025
        }}>
            <ThreeDots
                visible={true}
                height="80"
                width="80"
                color="#6c757d"
                radius="9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClass=""
            />
        </div>
    );
}

export default Loader;
