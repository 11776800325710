import React from 'react';
import Modal from 'react-bootstrap/Modal';

function Messages(props) {
    return (
        <Modal className='modal' show={props.message != null} onHide={() => {props.setMessage(null)}}>
            <Modal.Body>
                <div className='d-flex justify-content-center m-5 text-center'>
                    <p className='m-0'><pre>{props.message}</pre></p>
                </div>
            </Modal.Body>
        </Modal>)

}

export default Messages;