import homePicture from "../../../../pictures/homePicture.jpg"
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../Main.css"

function HomePartialViewMobile() {
  return (
    <>
      <div style={{ height: "clamp(80px, 10vh, 120px)" }}></div>
      <div className="container-fluid d-flex justify-content-center align-items-center"
        style={{
          backgroundImage: `url(${homePicture})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          height: "80vh"
        }}>
        <h4 className="home-font mt-5 text-center" style={{ color: "white" }}>INTERIOR DESIGN FOR RESIDENTIAL AND PUBLIC BUILDINGS</h4>
      </div>
    </>
  );
}

export default HomePartialViewMobile;
