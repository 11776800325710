import homePicture from "../../../../pictures/homePicture.jpg"
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../Main.css"

function HomePartialView() {
  return (
    <>
      <div style={{ height: "clamp(80px, 12vw, 120px)" }}></div>
      <div style={{ backgroundColor: "#7A7874" }}>
        <div className="container-fluid d-flex justify-content-center align-items-center"
          style={{
            backgroundImage: `url(${homePicture})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "80%",
            height: "45vw"
          }}>
          <h4 className="home-font mt-5 text-center" style={{ color: "white" }}>INTERIOR DESIGN FOR RESIDENTIAL AND PUBLIC BUILDINGS</h4>
        </div>
      </div>
    </>
  );
}

export default HomePartialView;
