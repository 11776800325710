import React from "react";
import logo from "../../pictures/logo.png"
import 'bootstrap/dist/css/bootstrap.min.css';
import './Header.css'

function HeaderMobile() {
  return (
    <div className="w-100 position-fixed fixed-top bg-white">
      <div className="d-flex justify-content-center align-items-center mt-2 mb-2" style={{ marginLeft: "10%", marginRight: "10%" }}>
        <img className="img-fluid" src={logo} style={{ maxHeight: 'clamp(80px, 10vh, 120px)' }} />
      </div>
    </div>
  );
}

export default HeaderMobile;