import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js"
import HomePartialView from "./PartialViews/HomePartialView/HomePartialView.js";
import AboutPartialView from "./PartialViews/AboutPartialView/AboutPartialView.js";
import ProjectsPartialView from "./PartialViews/ProjectsPartialView/ProjectsPartialView.js";
import ContactPartialView from "./PartialViews/ContactPartialView/ContactPartialView.js";
import HeaderMobile from "../../components/Header/HeaderMobile.js";
import HomePartialViewMobile from "./PartialViews/HomePartialView/HomePartialViewMobile.js";
import AboutPartialViewMobile from "./PartialViews/AboutPartialView/AboutPartialViewMobile.js";
import ProjectsPartialViewMobile from "./PartialViews/ProjectsPartialView/ProjectsPartialViewMobile.js";
import { Element } from "react-scroll";
import 'bootstrap/dist/css/bootstrap.min.css';

function Main() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 850);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const disableRightClick = (e) => {
      e.preventDefault();
    };

    window.addEventListener('contextmenu', disableRightClick);

    return () => {
      window.removeEventListener('contextmenu', disableRightClick);
    };
  }, []);

  return (
    <div className="container-fluid h-100 d-flex flex-column p-0">
      {!isMobile && <Header />}
      {isMobile && <HeaderMobile />}
      <div style={{ minHeight: "fit-content" }}>
        <Element name="homepage">
          {!isMobile && <HomePartialView />}
          {isMobile && <HomePartialViewMobile />}
        </Element>
        <Element name="about">
          {!isMobile && <AboutPartialView />}
          {isMobile && <AboutPartialViewMobile />}
        </Element>
        <Element name="projects">
          {!isMobile && <ProjectsPartialView />}
          {isMobile && <ProjectsPartialViewMobile />}
        </Element>
        <Element name="contact">
          <ContactPartialView isMobile={isMobile} />
        </Element>
        <div className="d-flex justify-content-center pt-2">
          <h5 className="copy-right-font mb-0">
            © {new Date().getFullYear()} Gabi Interior Design. All rights reserved.
          </h5>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Main;
