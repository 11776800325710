import aboutPicture from "../../../../pictures/aboutPicture.jpg"
import designerPhoto from "../../../../pictures/designerPhoto.jpg"
import "../../Main.css"
import 'bootstrap/dist/css/bootstrap.min.css';

function AboutPartialView() {
  return (
    <>
      <div className="d-flex justify-content-center align-items-center"
        style={{
          backgroundColor: "#353535",
          minHeight: "fit-content",
          paddingRight: "10%",
          paddingLeft: "10%"
        }} >
        <h6 className="m-4" style={{ color: "#969696", fontStyle: "italic", textAlign: "center" }}>"Nature is the greatest designer, innovator, and artist. It inspires me every day to create something new, something beautiful." - Alexander McQueen</h6>
      </div>
      <div className="d-flex flex-row"
        style={{
          backgroundImage: `url(${aboutPicture})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "50vw",
          maxHeight: "80vh",
          paddingRight: "10%",
          paddingLeft: "10%"
        }}>
        <img className="img-fluid flex-item" src={designerPhoto} style={{ height: '50vw', maxHeight: "80vh" }} />
        <div className="col d-flex justify-content-center flex-column" style={{ marginLeft: "10%" }} >
          <p className="about-paragraph">CERTIFIED INTERIOR DESIGNER.</p>
          <p className="about-paragraph">SPECIALIZING IN THE DESIGN OF INDIVIDUAL AND PUBLIC BUILDINGS IN VARIOUS ARCHITECTURAL STYLES.</p>
          <p className="about-list-items">WORKING ON FULL-SCALE INTERIOR PROJECTS. INCLUDING:</p>
          <ul>
            <li className="about-list-items">PREPARING TECHNICAL DRAWINGS</li>
            <li className="about-list-items">SELECTING FINISHING MATERIALS AND FURNITURE</li>
            <li className="about-list-items">CARRY OUT CONSTRUCTION SUPERVISION</li>
            <li className="about-list-items">DESIGNING CUSTOM-MADE FURNITURE</li>
          </ul>
          <p className="about-paragraph">IN MY INTERIOR DESIGN WORK, I FOCUS ON ADDING CREATIVITY TO EVERYDAY LIFE AND PAYING ATTENTION TO EVEN THE SMALLEST DETAILS. I REALLY ENJOY HELPING PEOPLE AND BUSINESSES MAKE THEIR HOMES OR PUBLIC SPACES FUNCTIONAL AND WELCOMING.</p>
          <p className="about-paragraph">I FIRMLY BELIEVE THAT THE ULTIMATE MEASURE OF SUCCESS LIES IN THE HAPPINESS OF MY CLIENTS. I FIND GREAT JOY IN ACHIEVING THIS OUTCOME THROUGH THE CREATION OF AESTHETICALLY PLEASING AND HARMONIOUS ENVIRONMENTS.</p>
        </div>
      </div>
    </>
  );
}

export default AboutPartialView;
