import React from "react";
import Project from "../../../../components/PartialViews/ProjectPartialView/Project";

function ProjectsPartialView() {
  return (
    <div className="container-fluid d-flex justify-content-between flex-column px-5" style={{ paddingTop: "4%", paddingBottom: "4%" }}>
      <div className="d-flex flex-row mb-5">
        <Project folderName={"project1"} picturesCount={3} isMobile={false}/>
        <Project folderName={"project2"} picturesCount={3} isMobile={false}/>
        <Project folderName={"project3"} picturesCount={3} isMobile={false}/>
      </div>
      <div className="d-flex flex-row">
        <Project folderName={"project4"} picturesCount={5} isMobile={false}/>
        <Project folderName={"project5"} picturesCount={4} isMobile={false}/>
        <Project folderName={"project6"} picturesCount={4} isMobile={false}/>
      </div>
    </div>
  );
}

export default ProjectsPartialView;
