import React from "react";
import { FaInstagram } from "react-icons/fa6";
import { FaSquareFacebook } from "react-icons/fa6";
import 'bootstrap/dist/css/bootstrap.min.css';

function Footer() {
  const handleInstagramRedirect = () => {
    window.location.href = 'https://www.instagram.com/gabi_interiordesign/';
  };

  const handleFaceBookRedirect = () => {
    window.location.href = 'https://www.facebook.com/profile.php?id=61557032222790';
  };

  return (
    <>
      <div style={{ height: "clamp(50px, 12vw, 60px)" }}></div>
      <div className="d-flex justify-content-center w-100 position-fixed fixed-bottom bg-white" style={{ height: "clamp(50px, 12vw, 60px)" }}>
        <button className="btn btn-outline-secondary border-0 m-1" onClick={handleInstagramRedirect}><FaInstagram size={"clamp(22px, 2vh, 26px)"} /></button>
        <button className="btn btn-outline-secondary border-0 m-1" onClick={handleFaceBookRedirect}><FaSquareFacebook size={"clamp(22px, 2vh, 26px)"} /></button>
      </div>
    </>
  );
}

export default Footer;