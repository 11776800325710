import React from "react";
import { Link } from "react-scroll";
import logo from "../../pictures/logo.png"
import 'bootstrap/dist/css/bootstrap.min.css';
import './Header.css'

function Header() {
  return (
    <div className={`w-100 position-fixed fixed-top bg-white`} style={{ height: "clamp(100px, 12vw, 120px)" }}>
      <div className="d-flex justify-content-between align-items-center" style={{ marginLeft: "10%", marginRight: "10%" }}>
        <Link activeClass="active"
          to="homepage"
          spy={true}
          smooth={true}
          duration={500}
          className="menu-button"
          style={{ height: "100%", cursor: "pointer" }}>
          HOME
        </Link>
        <Link activeClass="active"
          to="about"
          spy={true}
          smooth={true}
          duration={500}
          className="menu-button"
          style={{ height: "100%", cursor: "pointer"  }}>
          ABOUT
        </Link>
        <img className="img-fluid" src={logo} style={{ maxHeight: 'clamp(80px, 12vw, 110px)' }} />
        <Link activeClass="active"
          to="projects"
          spy={true}
          smooth={true}
          duration={500}
          className="menu-button"
          style={{ height: "100%", cursor: "pointer"  }}>
          PROJECTS
        </Link>
        <Link activeClass="active"
          to="contact"
          spy={true}
          smooth={true}
          duration={500}
          className="menu-button"
          style={{ height: "100%", cursor: "pointer"  }}>
          CONTACT
        </Link>
      </div>
    </div>
  );
}

export default Header;