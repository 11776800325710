import React from "react";
import Project from "../../../../components/PartialViews/ProjectPartialView/Project.js";

function ProjectsPartialViewMobile() {
  return (
    <div className="container-fluid d-flex justify-content-between flex-column px-5" style={{ paddingTop: "4%", paddingBottom: "4%" }}>
      <Project folderName={"project1"} picturesCount={3} isMobile={true} />
      <Project folderName={"project2"} picturesCount={3} isMobile={true} />
      <Project folderName={"project3"} picturesCount={3} isMobile={true} />
      <Project folderName={"project4"} picturesCount={5} isMobile={true} />
      <Project folderName={"project5"} picturesCount={4} isMobile={true} />
      <Project folderName={"project6"} picturesCount={4} isMobile={true} />
    </div>
  );
}

export default ProjectsPartialViewMobile;
