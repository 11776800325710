import React, { useState } from "react";
import Main from "./containers/MainView/Main.js"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Messages from "./components/Messages/Messages.js";

export const MessageContext = React.createContext(null);

function ReactRouterSetup() {
  const [message, setMessage] = useState(null);
  return (
    <>
      <Messages message={message} setMessage={setMessage} />
      <Router>
        <MessageContext.Provider value={{ message: message, setMessage: setMessage }}>
          <Routes>
            <Route path="/" element={<Main />} />
          </Routes>
        </MessageContext.Provider>
      </Router>
    </>
  );
}

export default ReactRouterSetup;