import React, { useEffect, useState } from "react";
import Loader from "./Loader";
import { PiHandTapThin } from "react-icons/pi";

function Project(props) {
    const [pictureName, setPictureName] = useState(1);
    const [openedProject, setOpenedProject] = useState(false);
    const [projectPicture, setProjectPicture] = useState(require(`../../../pictures/${props.folderName}/${pictureName}.jpg`));
    const [isLoading, setIsLoading] = useState(true);

    const handleProjectPictureClick = () => {
        setIsLoading(true);
        setPictureName(pictureName < props.picturesCount ? pictureName + 1 : 1);
        setOpenedProject(true);
    };

    useEffect(() => {
        const projectPicture = require(`../../../pictures/${props.folderName}/${pictureName}.jpg`);
        setProjectPicture(projectPicture);
    }, [pictureName]);

    const stopLoader = () => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 500);

        return () => clearTimeout(timer);
    };

    return (
        <>
            {!props.isMobile && <div className="px-3" onClick={handleProjectPictureClick} style={{ position: "relative", cursor: "pointer" }}>
                <img
                    className="img-fluid flex-item image"
                    src={projectPicture}
                    style={{ width: '33vw', height: '35vw' }}
                    onLoad={() => {stopLoader()}}
                    onError={() => {stopLoader()}}
                />

                {isLoading && <Loader />}
            </div>}

            {props.isMobile && <div className="container-fluid d-flex justify-content-end align-items-end px-3 mb-5" onClick={handleProjectPictureClick} style={{ position: "relative", cursor: "pointer" }} >
                <img
                    className="img-fluid flex-item"
                    src={projectPicture}
                    style={{ height: '100%' }}
                    onLoad={() => {stopLoader()}}
                    onError={() => {stopLoader()}}
                />

                {!openedProject && <PiHandTapThin
                    style={{
                        position: "absolute",
                        top: "90%",
                        left: "85%",
                        transform: "translate(-50%, -50%)",
                        width: "16%",
                        height: "16%",
                        zIndex: 1030,
                        color: "white"
                    }} />}

                {isLoading && <Loader />}
            </div>}
        </>
    );
}

export default Project;
